/* This file is GENERATED and will be DELETED each time the generator runs. */
/*             Do NOT modify anything here! You've been warned.             */

export enum Admin {
    None = 0,
    Login = 1,
    User = 2,
    UserGroup = 3,
    UserRight = 4,
    Configuration = 5,
    WeekLink = 6,
    Translation = 7,
    Partner = 8,
    CategoryFavorite = 9,
    Language = 10,
    Vario = 11,
    PriceGroup = 12,
    DiscountAction = 13,
    DiscountVoucher = 14,
    CommodityLine = 15,
    Delivery = 16,
    Payment = 17,
    Article = 18,
    Category = 19,
    ParameterValue = 20,
    Banner = 21,
    RestrictedIp = 22,
    CommodityMainImage = 23,
    CommodityGalleryImage = 24,
    Exchange = 25,
    Review = 26,
    Pack = 27,
    CommodityAward = 28,
    ParameterTranslation = 29,
    LeasingProduct = 30,
    CommodityDisable = 31,
    CommonSetting = 32,
    ActionCategory = 33,
    FreeItem = 34,
    CommodityArtist = 35,
    CommodityGroup = 36,
    FastOrderVoucher = 37,
    PrideSerie = 38,
    PrideItem = 39,
    InfoList = 40,
    Cashback = 41,
    CommodityComparison = 42,
    TechZoneEvent = 43,
    Holiday = 44,
    Commodity = 45,
    TranslationRequest = 46,
    MailListAction = 47,
    JobOffer = 48,
    PriceComparerBindingOptimisationSchedule = 49,
    InfoMessage = 50,
    ParameterType = 51,
    ParameterUnit = 52,
    ParameterUnitType = 53,
    ParameterGroup = 54,
    Producer = 55,
    UserReward = 56,
    Slot = 57,
    PriceSlasher = 58,
    SlotOriginal = 59,
    SearchWhispererSegmentSuspend = 60,
    WebshareDocumentState = 61,
    WebshareDocumentItemState = 62,
    TranslationDeadline = 63,
    Warranty = 64,
    UserRewardSetting = 65,
    PackUnit = 66,
    Section = 67,
    DeliveryBranch = 68,
    TranslationGroupCollection = 69,
    CommodityImagesToApproveInstruction = 70,
    TranslationSettings = 71,
    TranslationSentence = 72,
    AccessoryType = 73,
    WebSetting = 74,
    AccessoryGroup = 75,
    AlzaSet = 76,
    ArticleCategory = 77,
    GoodText = 78,
    JobPositionDepartment = 79,
    JobPositionPlace = 80,
    MailingSegment = 81,
    SeoOffer = 82,
    UrlRewriteRule = 83,
    VendorGuru = 84,
    VendorCommodity = 85,
    CommodityInstallation = 86,
    LeasingInsurance = 87,
    ImagePlatformDensity = 88,
    TranslationException = 89,
    PriceCompareCompetitor = 90,
    PriceCompareSegmentCascadeSetting = 91,
    CommodityTranslationManagementRank = 92,
    GeoArea = 93,
    CommodityManagementDeadline = 94,
    CommodityManagementTime = 95,
    CommodityManagementRule = 96,
    Wizard = 97,
    VideoReviewArticle = 98,
    PromoSection = 99,
    LimitationProcessedSegment = 100,
    CommodityPack = 101,
    HelpDeskGroup = 102,
    RepeatedTranslation = 103,
    AliasTextCategory = 104,
    AliasText = 105,
    StudentsDiscount = 106,
    PriceKiller = 107,
    PopUp = 108,
    DiscountActions = 109,
    DiscountVouchers = 110,
    FastOrderVouchers = 111,
    BasketOrderDelivery = 112,
    GiftAdvisor = 113,
    Survey = 114,
    ReviewToResolve = 115,
    GroupPositionTemplate = 116,
    MailBlackList = 117,
    CommodityRedirect = 118,
    MailList = 119,
    CommodityLegend = 120,
    CommodityLegendTemplate = 121,
    LocalTitlePageItem = 122,
    ParameterTypeEnum = 123,
    CommodityPrefix = 124,
    Platform = 125,
    TagTranslation = 126,
    CommodityARModel = 127,
    Badge = 128,
    Campaign = 129,
    CommodityDetail = 130,
    CommodityDiscussionPost = 131,
    CommodityManual = 132,
    CompatibleParameterValues = 134,
    DiscussionExpertise = 135,
    DiscussionExpertiseNotificationSettings = 136,
    Eshop = 137,
    FillRule = 138,
    GiftAction = 139,
    GiftActionEshop = 140,
    GiftActionPriceRange = 141,
    Group = 142,
    JobPositionEmployee = 143,
    JobPositionSubDepartment = 144,
    LocalTitlePage = 145,
    LocalTitlePageItemCommodity = 147,
    MailingAction = 148,
    MailingActionImage = 149,
    MailingTemplate = 150,
    Paragraph = 151,
    ParagraphImage = 152,
    ParagraphItem = 153,
    ParagraphItemDefinition = 154,
    ParameterAutoFillRule = 155,
    ParameterAutoFillRuleGroup = 156,
    PaymentPlatform = 157,
    Phrase = 158,
    PhraseVariant = 159,
    PoiDevice = 160,
    Position = 161,
    Publisher = 162,
    ReclamationServiceEngineer = 163,
    RewriteObject = 164,
    RobotQueueItem = 165,
    SearchRedirect = 166,
    SearchRedirectWord = 167,
    Tag = 168,
    Template = 169,
    TranslationDefinition = 170,
    TranslationKey = 171,
    UserPermission = 172,
    UserTask = 173,
    Version = 174,
    WebUser = 175,
    CommodityLegendAsset = 176,
    Link = 177,
    CommodityIcon = 178,
    Feed = 179,
}
