/* This file is GENERATED and will be DELETED each time the generator runs. */
/*             Do NOT modify anything here! You've been warned.             */

export enum Partner {
    FileSystemItem = 0,
    Partner = 1,
    PickupPlace = 2,
    PartnerUser = 3,
    VoucherCampaign = 4,
    VoucherCoupon = 5,
    AdminUser = 6,
    PartnerCareTicket = 7,
    Warehouse = 8,
    Accountancy = 9,
    Logistics = 10,
    Shipping = 11,
    Contact = 12,
    Order = 13,
    Product = 14,
    TermsAndConditions = 15,
    VoucherSettings = 16,
    ProductImport = 17,
    ContentTemplate = 18,
    Notification = 19,
    ProductGroup = 20,
    ProductPriceAndAvailability = 21,
    ApiCommunication = 22,
}
